export const sleep = (duration = 1000) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
});

export const addGeoJsonToMap = (geoJson, map, AMap) => {
  const target = new AMap.GeoJSON({
    geoJSON: geoJson,
    getPolygon: function(geojson, lnglats) {
      return new AMap.Polygon({
        path: lnglats,
        strokeColor: 'blue',
        fillColor: '',
        fillOpacity: 0,
      });
    }
  });
  map.add(target);
}

export const findMax = (data) => {
  let max = 0;
  data.forEach(({ count }) => {
    if(count > max) max = count;
  });
  return max + 1;
}
