import Map from '../../components/Map'

function HomePage() {
  return (
    <div className='HomePage'>
      <Map mode="homeMap"/>
    </div>
  );
}

export default HomePage;