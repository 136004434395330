export const DEFAULT_NAMESPACE = 'map-home';

export const DEFAULT_STATE = {
  mapReady: false,
  dataReady: false,

  types: [], // 人员类型
  positions: [], // 工种
  departments: [], //部门
  orgs: [], // 公司
  digitaldences: [],

  // 区域列表
  regions: [],

  view: 'type',
  map_type: 'gps',  // gps || heat

  panelVisible: false,
  panelType: 'searchPanel',  // personPanel | viewPanel | searchPanel

  curPersonInfo: {},

  searchType: 'card_no',

  disableNewWarning: false,
  warningList: [],

  noticeDrawerVisible: false
};

// websocket 配置参数
export const SOCKET_OPTIONS = {
  autoConnect: true,
  transports: ['websocket'],
  withCredentials: false
}

export const VIEWS = {
  all: '全部', // 只有热力图中有
  type: '按类型',
  position: '按工种',
  department: '按部门',
  org: '按公司'
}

export const MAP_TYPE = {
  gps: '定位图',
  heat: '热力图',
}

export const SearchTypeOption = [
  { label: 'card_no', value: '卡号' },
  { label: 'p_name', value: '姓名' },
  { label: 'p_no', value: '工号' },
];

export const SearchTypeMap = {
  card_no: '卡号',
  p_name: '姓名',
  p_no: '工号',
}

export const HeatMapDimensionMap = {
  type: 'type',
  position: 'post',
  org: 'org',
  department: 'depart',
}