import dva from 'dva';
import { createBrowserHistory } from 'history';
import './index.css';

const app = dva({
  history: createBrowserHistory('')
});
app.model(require('./models').default);
app.router(require('./router').default);
app.start('#root');
