import React from 'react';
import { connect } from 'dva';
import { DEFAULT_NAMESPACE } from '../../../constants';
import { Radio } from 'antd';

const ViewPanel = ({
  view,
  handleSwitchView
}) => {

  return (
    <div className='settingPanel'>
      <div className='settingPanel-label'>显示设置：</div>
      {/* 需判断宽度 */}
      <Radio.Group 
        size="mini" 
        value={view} 
        buttonStyle="solid">
        <Radio.Button value="type" onClick={() => handleSwitchView("type")}>按类型</Radio.Button>
        <Radio.Button value="position" onClick={() => handleSwitchView("position")}>按工种</Radio.Button>
        <Radio.Button value="department" onClick={() => handleSwitchView("department")}>按部门</Radio.Button>
        <Radio.Button value="org" onClick={() => handleSwitchView("org")}>按公司</Radio.Button>
      </Radio.Group>
    </div>
  )
}
export default connect(
  (state) => {
    const { view } = state[DEFAULT_NAMESPACE];
    return {
      view
    }
  },
  (dispatch) => ({
    handleSwitchView(view) {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/handleSwitchView`,
        view
      });
    }
  })
)(ViewPanel);