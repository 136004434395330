import { BrowserRouter, Route, Switch } from 'dva/router';
import HomePage from './routes/HomePage';

function RouterConfig({ history }) {
  return (
    <BrowserRouter history={history}>
      <Switch>
        <Route path="/" component={HomePage} />     
      </Switch>
    </BrowserRouter>
  );
}

export default RouterConfig;
