import io from 'socket.io-client';
import { 
  DEFAULT_STATE,
  DEFAULT_NAMESPACE,
  SOCKET_OPTIONS
} from '../constants';
import { 
  getType, 
  getPosition, 
  getDepartment,
  getOrgs,
  getDigitalFences,
  getWarningList,
  // getRegions, 
  getRegions, 
  postRegion, 
  putRegion, 
  getHeatmapData,
  getPersonDetail
} from '../api/services'

export default {
  namespace: DEFAULT_NAMESPACE,

  state: DEFAULT_STATE,

  subscriptions: {
    setup({ dispatch, history }) {
      history.listen((params) => {
        dispatch({
          type: 'init'
        });
      })
      
    },
  },
  effects: {
    *init(_, { call, put }) {
      // 获取基础数据
      const types = yield call(getType);
      const positions = yield call(getPosition);
      const departments = yield call(getDepartment);
      const orgs = yield call(getOrgs);
      const digitalDences = yield call(getDigitalFences);

      const regions = yield call(getRegions);
      
      const warningList = yield call(getWarningList);
      console.log(warningList)
      
      yield put({
        type: 'setState',
        types,
        positions,
        departments,
        orgs,
        digitalDences,
        regions,
        warningList,
        dataReady: true
      })
    },
    *handleSwitchView({ view }, { put }) {
      yield put({
        type: 'setState',
        view
      })
    },
    *handleSwitchMapType({ map_type }, { put }) {
      yield put({
        type: 'setState',
        map_type,
        view: map_type === 'heat' ? '' : 'type'
      })
    }
  },

  reducers: {
    setState(state, { type, ...newState }) {
      return {
        ...state,
        ...newState
      };
    },
    mapReady(state) {
      return {
        ...state,
        mapReady: true
      };
    },
    addWarning(state, { warning }) {
      
      // state.disableNewWarning = !state.disableNewWarning
      state.warningList.unshift(warning);
      state.disableNewWarning = !state.disableNewWarning
      // console.log(state.warningList)
      return {
        ...state,
        warningList: state.warningList
      };
    },
    setCurPersonInfo(state, { curPersonInfo }) {
      return {
        ...state,
        panelVisible: true,
        panelType: 'personPanel',
        curPersonInfo: curPersonInfo
      };
    },
    clearCurPersonInfo(state, { curPersonInfo }) {
      return {
        ...state,
        panelVisible: false,
        curPersonInfo: {}
      };
    },
    setDrawerStatus(state, { status }) {
      if (status){
        return {
          ...state,
          noticeDrawerVisible: status
        };
      } else {
        return {
          ...state,
          noticeDrawerVisible: !state.noticeDrawerVisible
        };
      }
    },
    setPanelStatus(state, { status }) {
      if (status){
        return {
          ...state,
          panelVisible: status
        };
      } else {
        return {
          ...state,
          panelVisible: !state.panelVisible
        };
      }
    },
    openPanel(state, { panelType }) {
      return {
        ...state,
        panelVisible: true,
        panelType
      };
    }
  },

};