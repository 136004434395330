import React from 'react';
import { connect } from 'dva';
import { DEFAULT_NAMESPACE, VIEWS, MAP_TYPE } from '../../constants'
import personIcon from "../../assets/icons/person.svg"
import switchIcon from "../../assets/icons/switch.svg"
import searchIcon from "../../assets/icons/search.svg"
import noticeIcon from "../../assets/icons/notice.svg"
import InfoPanel from "../InfoPanel"

const ToolBar = ({
  view,
  map_type,
  panelVisible,
  panelType,
  handleSearch,
  handleSwitchNoticeDrawer,
  handleClosePanel,
  handleOpenPanel,
  removeMapActive
}) => {

  const handleSwitchPanel = (type) => {
    if (panelVisible && panelType === 'personPanel' && type !== 'personPanel') removeMapActive();
    if(panelVisible && panelType === type) {
      handleClosePanel();
    } else {
      handleOpenPanel(type);
    }
  }

  return (
    <div className="fixed-left-top switch-card">
      <div className="switch-btn" onClick={() => handleSwitchPanel('viewPanel')}>
        <img className="switch-btn-icon" src={personIcon} />
        {VIEWS[view] || '全部'}
      </div> |
      <div className="switch-btn" onClick={() => handleSwitchPanel('mapTypePanel')}>
        <img className="switch-btn-icon" src={switchIcon} />
        {MAP_TYPE[map_type]}
      </div> | 
      <div className="switch-btn" onClick={() => handleSwitchPanel('searchPanel')}>
        <img className="switch-btn-icon" src={searchIcon} />
        搜索
      </div> ｜ 
      <div className="switch-btn" onClick={handleSwitchNoticeDrawer}>
        <img className="switch-btn-icon" src={noticeIcon} />
        报警
      </div>

      <InfoPanel removeMapActive={removeMapActive} handleSearch={handleSearch}/>
    </div>
  )
}
export default connect(
  (state, { removeMapActive }) => {
    const { 
      view,
      map_type,
      panelType,
      panelVisible
    } = state[DEFAULT_NAMESPACE];
    return {
      view,
      map_type,
      panelType,
      panelVisible,
      removeMapActive
    }
  },
  (dispatch) => ({
    handleSwitchNoticeDrawer() {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/setDrawerStatus`
      });
    },
    handleClosePanel() {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/setPanelStatus`,
        status: false
      });
    },
    handleOpenPanel(panelType) {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/openPanel`,
        panelType
      });
    },
  })
)(ToolBar);