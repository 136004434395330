import React from 'react';
import { connect } from 'dva';
import { DEFAULT_NAMESPACE, SearchTypeOption, SearchTypeMap } from '../../../constants';
import { Radio, Input } from 'antd';

const SearchPanel = ({
  searchType,
  handleSearch,
  handleSearchTypeChange,
}) => {

  return (
    <div className='searchPanel'>
      <Radio.Group 
        size="large" 
        onChange={handleSearchTypeChange} 
        value={searchType} 
        buttonStyle="solid">
        {
          SearchTypeOption.map(option => {
            return <Radio.Button value={option.label}>{option.value}</Radio.Button>
          })
        }
      </Radio.Group>
     
      <Input.Search 
        size="large" 
        placeholder={`按${SearchTypeMap[searchType]}搜索`} 
        onSearch={(val) => {handleSearch(val, searchType)}} 
        style={{outline: "none", marginTop: "10px"}}
      />
    </div>
  )
}
export default connect(
  (state) => {
    const { searchType } = state[DEFAULT_NAMESPACE];
    return {
      searchType
    }
  },
  (dispatch) => ({
    handleSearchTypeChange(e) {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/setState`,
        searchType: e.target.value
      });
    }
  })
)(SearchPanel);