import React from 'react';
import { connect } from 'dva';
import { DEFAULT_NAMESPACE } from '../../constants'
import { Drawer, Table, Switch,Tag } from 'antd';

const NoticeDrawer = ({
  warningList,
  switchVal,
  noticeDrawerVisible,
  handleDrawerClose,
  showWarningArea,
  handleSwitchWarningType
}) => {

  const columns = [
    {
      title: '报警名称',
      dataIndex: 'warning_content',
      render: (_, record) => (
        <Tag color={record.warning_color}>
          {record.warning_content}
        </Tag>
      )
    },
    {
      title: '姓名',
      dataIndex: 'p_name',
    },
    {
      title: '时间',
      dataIndex: 'cur_time',
    },
    {
      title: '级别',
      dataIndex: 'warning_level',
      render: (_, record) => {
        var name = '其他'
        if(record.warning_level_code=='error'){
          name = "错误"
        }else if(record.warning_level_code=='debug'){
          name = "调试"
        }else if(record.warning_level_code=='info'){
          name = "信息"
        }else if(record.warning_level_code=='warning'){
          name = "警告"
        }
        return (<a>{name}</a>)
      },
    },
    {
      title: '类型',
      dataIndex: 'warning_type',
      render: (_, record) => {
        var name = '其他'
        if(record.warning_type==1){
          name = "非法进入电子围栏"
        }else if(record.warning_type==2){
          name = "非法外出电子围栏"
        }else if(record.warning_type==3){
          name = "员工卡未佩戴"
        }else if(record.warning_type==4){
          name = "进入危险区域"
        }else if(record.warning_type==5){
          name = "人员主动报警"
        }
        return (<a>{name}</a>)
      },
    },
  ];
  

  return (
    <Drawer 
      title="报警列表" 
      placement="right"
      mask={false}
      size="large"
      onClose={handleDrawerClose} 
      visible={noticeDrawerVisible}
    >
      <div style={{ display: 'flex', alignItem: 'center' }}>
        <Switch
          style={{marginBottom: '20px', marginRight: '5px'}}
          checkedChildren="开启" 
          unCheckedChildren="开启"
          checked={switchVal}
          onChange={handleSwitchWarningType}
        />
        新报警
      </div>
      
      <Table 
        columns={columns}
        dataSource={[...warningList]}
        size="small"
        rowClassName='warning-table-row'
        onRow={(record) => {
          return {
            onClick: () => {
              const { warning_area } = record;
              showWarningArea(warning_area);
            }
          }
        }}
      />
    </Drawer>
  )
}
export default connect(
  (state) => {
    const { warningList, disableNewWarning, noticeDrawerVisible } = state[DEFAULT_NAMESPACE];

    return {
      warningList,
      switchVal: !disableNewWarning,
      noticeDrawerVisible
    }
  },
  (dispatch) => ({
    handleDrawerClose() {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/setDrawerStatus`,
        status: false
      });
    },
    handleSwitchWarningType(val) {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/setState`,
        disableNewWarning: !val
      });
    },
  })
)(NoticeDrawer);