import React from 'react';
import { connect } from 'dva';
import { DEFAULT_NAMESPACE } from '../../../constants';
import { Radio } from 'antd';

const MapTypePanel = ({
  map_type,
  handleSwitchMapType
}) => {

  return (
    <div className='mapTypePanel'>
      <div className='mapTypePanel-label'>地图类型：</div>
      <div>
      <Radio.Group 
        size="large" 
        value={map_type} 
        buttonStyle="solid">
        <Radio.Button value="gps" onClick={() => handleSwitchMapType("gps")}>定位图</Radio.Button>
        <Radio.Button value="heat" onClick={() => handleSwitchMapType("heat")}>热力图</Radio.Button>
      </Radio.Group>
      </div>
    </div>
  )
}
export default connect(
  (state) => {
    const { map_type } = state[DEFAULT_NAMESPACE];

    return {
      map_type
    }
  },
  (dispatch) => ({
    handleSwitchMapType(map_type) {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/handleSwitchMapType`,
        map_type
      });
    }
  })
)(MapTypePanel);