import React from 'react';
import { connect } from 'dva';
import { DEFAULT_NAMESPACE } from '../../constants';
import PersonPanel from './PersonPanel';
import ViewPanel from './ViewPanel';
import MapTypePanel from './MapTypePanel';
import SearchPanel from './SearchPanel';

const InfoPanel = ({
  panelVisible,
  panelType,
  handleClosePanel,
  handleSearch,
  removeMapActive
}) => {

  return (
    panelVisible && <div className='relative-panel'>
      <div className='panel-close-btn-line'>
        <div 
          className='panel-close-btn' 
          onClick={() => {
            if (panelType === 'personPanel'){
              removeMapActive();
            } else {
              handleClosePanel()
            }
          }}
        >
          X
        </div>
      </div>
      { panelType === 'personPanel' &&  <PersonPanel />}
      { panelType === 'viewPanel' &&  <ViewPanel />}
      { panelType === 'mapTypePanel' &&  <MapTypePanel />}
      { panelType === 'searchPanel' &&  <SearchPanel handleSearch={handleSearch}/>}
    </div>
  )
}
export default connect(
  (state, { removeMapActive }) => {
    const { panelType, panelVisible } = state[DEFAULT_NAMESPACE];

    return {
      panelType,
      panelVisible,
      removeMapActive
    }
  },
  (dispatch) => ({
    handleClosePanel() {
      dispatch({
        type: `${DEFAULT_NAMESPACE}/setPanelStatus`,
        status: false
      });
    }
  })
)(InfoPanel);