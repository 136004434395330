import React from 'react';
import { connect } from 'dva';
import { DEFAULT_NAMESPACE } from '../../../constants';
import { Button } from 'antd';

const PersonPanel = ({
  curPersonInfo
}) => {

  return (
    <div className='personPanel'>
      <div className='personPanel-item'>
        <div className='personPanel-label'>卡号</div>
        <div className='personPanel-info'>{curPersonInfo.card_no}</div>
      </div>
      <div className='personPanel-item'>
        <div className='personPanel-label'>姓名</div>
        <div className='personPanel-info'>{curPersonInfo.p_name}</div>
      </div>
      <div className='personPanel-item'>
        <div className='personPanel-label'>工号</div>
        <div className='personPanel-info'>{curPersonInfo.p_no}</div>
      </div>
      <div className='personPanel-item'>
        <div className='personPanel-label'>部门</div>
        <div className='personPanel-info'>{curPersonInfo.p_depart}</div>
      </div>
      <div className='personPanel-item'>
        <div className='personPanel-label'>职位</div>
        <div className='personPanel-info'>{curPersonInfo.p_post_name}</div>
      </div>
      <div className='personPanel-item'>
        <div className='personPanel-label'>类型</div>
        <div className='personPanel-info'>{curPersonInfo.p_type_name}</div>
      </div>
      <div className='personPanel-item'>
        <div className='personPanel-label'>时刻</div>
        <div className='personPanel-info'>{curPersonInfo.cur_time}</div>
      </div>
      <Button size="small" type="primary" ghost disabled block>
        查看历史轨迹
      </Button>
    </div>
  )
}
export default connect(
  (state) => {
    const { curPersonInfo } = state[DEFAULT_NAMESPACE];

    return {
      curPersonInfo
    }
  },
  (dispatch) => ({
  })
)(PersonPanel);